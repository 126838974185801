import * as React from "react";
import "./App.css";
// import Dashboard from "./Dashboard";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { gql, useLazyQuery, useMutation } from "@apollo/client";

import TextField from "@mui/material/TextField";

const GET_SHOPS = gql`
  query ShowAllCustomers {
    showAllCustomers {
      customer
      id
      shopName
    }
  }
`;

const UPDATE_SHOP = gql`
  mutation ChangeCustomer($shop: ShopCustomerInput!) {
    changeCustomer(shop: $shop)
  }
`;

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "customer",
    headerName: "Customer",
    width: 150,
    editable: false,
  },
  {
    field: "shopName",
    headerName: "Shop Name",
    width: 150,
    editable: false,
  },
];

const drawerWidth = 240;

function App() {
  const [loadData, { data, error }] = useLazyQuery(GET_SHOPS);
  const [updateShop] = useMutation(UPDATE_SHOP);
  // const { loading, error, data } = useQuery(GET_SHOPS);
  const [open, setOpen] = React.useState(false);
  const [sopen, setSOpen] = React.useState(false);
  const [shop, setShop] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [rowsSet, setRowsSet] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      setSOpen(true);
      setRows([]);
    }
  }, [error]);

  if (rowsSet && data && data.showAllCustomers) {
    let shopArr = data.showAllCustomers;
    let arr = shopArr.map((s, index) => {
      return {
        id: index,
        custid: s.id,
        customer: s.customer,
        shopName: s.shopName,
      };
    });
    setRows(arr);

    setRowsSet(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSClose = () => {
    setSOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    updateShop({
      variables: {
        shop: {
          customer: `${shop.customer}`,
          id: Number.parseInt(shop.custid),
          shopName: `${shop.shopName}`,
        },
      },
    });
    setOpen(false);
  };

  const handleButton = () => {
    loadData();
    setRowsSet(true);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
          >
            <Toolbar>
              <Typography variant="h6" noWrap component={"span"}>
                Admin Power
              </Typography>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <Divider />
            <List>
              {["Change Shop"].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{<BuildCircleIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Box component="main" sx={{ height: 1400, width: "100%" }}>
            <Toolbar />
            <TextField
              sx={{ marginLeft: 1, marginTop: 2, width: "50%" }}
              id="standard-basic"
              label="Api Key"
              variant="standard"
              value={localStorage.getItem("token")}
              onChange={(e) => {
                localStorage.setItem("token", e.target.value);
              }}
            />
            <Button sx={{ margin: [2, 2, 2, 2] }} variant="contained" onClick={handleButton}>
              Fetch Shops
            </Button>
            <Divider />

            <DataGrid
              rows={rows}
              columns={columns}
              onRowClick={(x) => {
                setShop(x.row);
                handleClickOpen();
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to change the shop?
              <br />
              <br />
              Shop Name: {shop.shopName}
              <br />
              Customer: {shop.customer}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleYes} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={sopen}
          autoHideDuration={6000}
          onClose={handleSClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert onClose={handleSClose} severity="error" sx={{ width: "100%" }}>
            Wrong api key
          </Alert>
        </Snackbar>
      </header>
    </div>
  );
}

export default App;
